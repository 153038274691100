import "styles/pages/page-wedding.scss";
import React from "react";
import Layout from "components/Layout";
import Seo from "components/Seo";
import SubpageHeader from "components/SubpageHeader";
import ImageWithText from "../components/ImageWithText";
import Breadcrumbs from "../components/Breadcrumbs";

const WeddingPage = ({ pageContext }) => {
	const pageWeeding = pageContext.pageContent;
	return (
		<Layout headerColor="#6AD044">
			<Seo
				title={
					pageContext.seo.title
						? pageContext.seo.title
						: pageContext.title
				}
				description={
					pageContext.seo.metaDesc && pageContext.seo.metaDesc
				}
			/>
			<SubpageHeader
				image={require("assets/images/wedding-header-bg.jpg").default}
			/>
			<Breadcrumbs currentLocationLabel="Podróż poślubna" />
			<section className="wedding-section-main">
				<div className="container">
					<h2 className="section-title">
						{pageWeeding.weedingTitle}
					</h2>
					<div className="row">
						<div className="col-xl-5 col-md-6 offset-xl-1">
							<div
								className="wedding-section-main__text"
								dangerouslySetInnerHTML={{
									__html: pageWeeding.weedingDesc,
								}}
							></div>
						</div>
						<div className="col-md-5 offset-md-1">
							<img
								src={pageWeeding.weedingImg?.sourceUrl}
								alt=""
								className="wedding-section-main__img img-fluid"
							/>
						</div>
					</div>
				</div>
			</section>
			<section className="wedding-tiles-1">
				<div className="container">
					<div className="row">
						{pageWeeding.mainAttractions1.map((node, index) => {
							return (
								<div className="col-md-6" key={index}>
									<ImageWithText
										image={node.img?.sourceUrl}
										title={node.title}
										text={node.desc}
										marginBottom={150}
										textPadding={150}
									/>
								</div>
							);
						})}
					</div>
				</div>
			</section>
			<section className="wedding-tiles-2">
				<div className="container">
					<div className="row">
						{pageWeeding.mainAttractions2.map((node, index) => {
							return (
								<div className="col-md-6" key={index}>
									<ImageWithText
										image={node.img?.sourceUrl}
										title={node.title}
										text={node.desc}
										marginBottom={150}
										textPadding={150}
									/>
								</div>
							);
						})}
					</div>
				</div>
			</section>
			<section className="wedding-tiles-3">
				<div className="container">
					<ImageWithText
						image={pageWeeding.seaImg?.sourceUrl}
						title={pageWeeding.seaTitle}
						text={pageWeeding.seaDesc}
						horizontal={true}
					/>
				</div>
			</section>
		</Layout>
	);
};

export default WeddingPage;
