import "./image-with-text.scss";
import React from "react";

const ImageWithText = ({
  image,
  title,
  text,
  marginBottom,
  textPadding,
  horizontal,
}) => {
  return (
    <>
      {!horizontal && (
        <div
          className="image-with-text"
          style={{ marginBottom: marginBottom ? marginBottom : 70 }}
        >
          <img src={image} alt="" className="img-fluid" />
          <div className="image-with-text__title">{title}</div>
          <div
            className="image-with-text__text"
            dangerouslySetInnerHTML={{ __html: text }}
            style={{
              paddingLeft: textPadding ? textPadding : 50,
              paddingRight: textPadding ? textPadding : 50,
            }}
          />
        </div>
      )}
      {horizontal && (
        <div className="image-with-text image-with-text--horizontal">
          <div className="row align-items-center">
            <div className="col-md-6">
              <img src={image} alt="" className="img-fluid" />
            </div>
            <div className="col-md-6">
              <div className="image-with-text__title">{title}</div>
              <div
                className="image-with-text__text"
                dangerouslySetInnerHTML={{ __html: text }}
                style={{
                  paddingLeft: textPadding ? textPadding : 50,
                  paddingRight: textPadding ? textPadding : 50,
                }}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ImageWithText;
